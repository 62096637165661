import { useEffect } from "react";
import WebFont from "webfontloader";
import { useEditorContext } from "../../hooks/useMouseMove";
import { defaultFonts, googleFontList, otherFonts } from "./FontList";

const FontLoader = (props) => {
  const { otherProps, readOnly } = props;
  const { setFontFamilies } = useEditorContext();

  const loadFontsInBatches = (families, batchSize = 5, maxRetries = 3) => {
    let currentIndex = 0;
    let retryCount = 0;

    function loadNextBatch() {
      if (currentIndex >= families?.length) {
        // console.log("All fonts have been loaded");
        return;
      }

      const batch = families?.slice(currentIndex, currentIndex + batchSize);
      const batchWithWeights = batch.map((font) => `${font}:300,400,600,700`);

      WebFont.load({
        google: {
          families: [...batchWithWeights],
        },
        classes: false,
        timeout: 2000,
        active: () => {
          // console.log(`Fonts loaded successfully: ${batch}`);
          currentIndex += batchSize;
          retryCount = 0; // Reset retry count for the next batch
          loadNextBatch();
        },
        inactive: () => {
          // console.log(`Font loading failed for: ${batch}`);

          if (retryCount < maxRetries) {
            retryCount++;
            // console.log(`Retrying batch (${retryCount}/${maxRetries})...`);
            // Retry loading the same batch
            loadNextBatch();
          } else {
            console.log(
              // `Max retries reached for batch: ${batch}. Moving to the next batch.`
            );
            currentIndex += batchSize;
            retryCount = 0; // Reset retry count for the next batch
            loadNextBatch();
          }
        },
      });
    }

    loadNextBatch();
  };

  useEffect(() => {
    let families = [...otherFonts, ...defaultFonts];
    if (!readOnly) {
      otherProps
        ?.services("listGoogleFont", [])
        .then((data) => {
          families = [...families, ...(data?.data || [])];
          const filteredfamilies = families?.filter(
            (font) => !font?.includes("Material")
          );
          setFontFamilies({
            id: 1,
            format: "fontFamily",
            type: "fontfamilydropdown",
            options: filteredfamilies || [],
          });

          loadFontsInBatches(families);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      function correctFontArray(fontString) {
        let fontsArray = fontString.split(",");

        let cleanedFontsArray = [
          ...new Set(fontsArray.map((font) => font.trim())),
        ];

        return cleanedFontsArray;
      }

      function sanitizeFontFamily(fontFamily) {
        const correctedFonts = correctFontArray(fontFamily);
        return correctedFonts.join(", ");
      }

      const elements = Array.from(document?.querySelectorAll("*"));
      const fontSet = new Set();

      elements.forEach((element) => {
        const computedStyles = window?.getComputedStyle(element);
        fontSet.add(sanitizeFontFamily(computedStyles?.fontFamily));
      });

      let families = Array.from(fontSet);
      families = correctFontArray(families.join(", "));
      families = families?.map((font) => font?.replace(/\"/g, ""));
      families = families?.map((font) => font?.replace(", sans-serif", "")); //This is temporary fix for patch
      families = families.filter(font => googleFontList.includes(font));
      loadFontsInBatches(families);
    }
  }, []);

  return null;
};

export default FontLoader;
